import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Orders } from '~api/orders';
import { callback } from '~api/payment';
const initialState = {
  data: '',
  dataSkip: 0,
  stateData: [],
  retryData: {},
  packageReceivedData: {},
  orderPaymentConfirmData: '',
  dataCallback: '',
  status: 'idle',
  statusCallback: 'idle',
  orderDataIndex: -1,
  orderDataCount: 0,
  orderDataList: [],
  orderCountStatus: 'idle',
  orderDataStatus: 'idle',
  orderStateStatus: 'idle',
  orderPaymentConfirmStatus: 'idle',
  packageReceivedStatus: 'idle',
  retryStatus: 'idle',
  dataOvoFailed: null,
  filter: {},
  filterRaw: {},
  trackOrderData: [],
  trackOrderStatus: 'idle',
  countOrdersWaitingPayment: 0,
  countOrdersWaitingPaymentStatus: 'idle',
};

export const createOrdersAsync = createAsyncThunk(
  'ORDERS/CREATE',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Orders.createRequest(payload);
      return response.data;
    } catch (err) {
      if (err.response.data.data.innerError) {
        return rejectWithValue(err.response.data.data.innerError);
      } else {
        // console.error(err.message)
        throw new Error('Something went wrong!');
      }
    }
  },
);

export const countOrdersAsync = createAsyncThunk(
  'ORDERS/COUNT',
  async (payload) => {
    try {
      const response = await Orders.countRequest(payload);
      return response.data;
    } catch (err) {
      // console.error(err.message)
      throw new Error('Something went wrong!');
    }
  },
);

export const countOrdersWaitingPaymentAsync = createAsyncThunk(
  'ORDERS/COUNT_WAITING_PAYMENT',
  async () => {
    try {
      const response = await Orders.countRequest({
        params: {
          filter: {
            'state.name': { $nin: ['Cancelled', 'Delivered'] },
            order_source: ['lulla', 'lulla_ios', 'lulla_android'],
          },
        },
      });
      return response.data;
    } catch (err) {
      // console.error(err.message)
      throw new Error('Something went wrong!');
    }
  },
);

export const readOrdersAsync = createAsyncThunk(
  'ORDERS/READ',
  async (payload) => {
    try {
      const response = await Orders.readRequest(payload);
      return response.data;
    } catch (err) {
      // console.error(err.message)
      throw new Error('Something went wrong!');
    }
  },
);

export const stateOrdersAsync = createAsyncThunk(
  'ORDERS_STATE/READ',
  async () => {
    const response = await Orders.stateRequest();
    return response.data;
  },
);

export const callbackPaymentAsync = createAsyncThunk(
  'ORDERS/CALLBACK',
  async (payload) => {
    const response = await callback(payload);
    return response.data;
  },
);

export const orderConfirmPaymentAsync = createAsyncThunk(
  'ORDERS/PAYMENT_CONFIRM',
  async (payload) => {
    try {
      const response = await Orders.paymentConfirmRequest(payload);
      return response.data;
    } catch (err) {
      // console.error(err.message)
      throw new Error('Something went wrong!');
    }
  },
);

export const bankAccountAsync = createAsyncThunk(
  'ORDERS/BANK_ACCOUNT',
  async (payload) => {
    try {
      const response = await Orders.bankAccountRequest(payload);
      return response.data;
    } catch (err) {
      // console.error(err.message)
      throw new Error('Something went wrong!');
    }
  },
);

export const trackOrderAsync = createAsyncThunk(
  'ORDERS/TRACK_ORDER',
  async (payload) => {
    try {
      const response = await Orders.trackRequest(payload);
      return response.data;
    } catch (err) {
      // console.error(err.message)
      throw new Error('Something went wrong!');
    }
  },
);

export const packageReceivedAsync = createAsyncThunk(
  'ORDERS/PACKAGE_RECEIVED',
  async (payload) => {
    try {
      const response = await Orders.packageReceivedRequest(payload);
      return response.data;
    } catch (err) {
      // console.error(err.message)
      throw new Error('Something went wrong!');
    }
  },
);

export const retryAsync = createAsyncThunk('ORDERS/RETRY', async (payload) => {
  try {
    const response = await Orders.retryRequest(payload);
    return response.data;
  } catch (err) {
    // console.error(err.message)
    throw new Error('Something went wrong!');
  }
});

export const retryByPhoneNumberAsync = createAsyncThunk(
  'ORDERS/RETRY_BY_PHONE_NUMBER',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Orders.retryByPhoneNumberRequest(payload);
      return response.data;
    } catch (err) {
      if (err.response.data.data.innerError) {
        return rejectWithValue(err.response.data.data.innerError);
      } else {
        // console.error(err.message)
        throw new Error('Something went wrong!');
      }
    }
  },
);

const paymentSlice = createSlice({
  name: 'ORDERS',
  initialState,
  reducers: {
    SET_DATA(state, action) {
      state.data = action.data;
    },
    SET_DATA_LIST(state, action) {
      state.orderDataList = action.data;
    },
    UPDATE_DATA_LIST(state, action) {
      const dataKey = state.orderDataList.findIndex(
        (res) => res._id === action.data._id,
      );
      state.orderDataList[dataKey] = Object.assign(
        {},
        state.orderDataList[dataKey],
        action.data,
      );
    },
    SET_DATA_SKIP(state, action) {
      state.dataSkip = action.data;
    },
    SET_DATA_CALLBACK(state, action) {
      state.dataCallback = action.data;
    },
    SET_DATA_OVO_FAILED(state, action) {
      state.dataOvoFailed = action.data;
    },
    SET_FILTER(state, action) {
      state.filter = action.data;
    },
    SET_FILTER_RAW(state, action) {
      state.filterRaw = action.data;
    },
    SET_TRACK_ORDER(state, action) {
      state.trackOrderData = action.data;
    },
    SET_STATE(state, { payload: [key, value] }) {
      state[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrdersAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createOrdersAsync.rejected, (state, action) => {
        state.dataOvoFailed = action.payload;
        state.status = 'error';
      })
      .addCase(createOrdersAsync.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.status = 'finished';
      })
      .addCase(callbackPaymentAsync.pending, (state, action) => {
        state.statusCallback = 'loading';
      })
      .addCase(callbackPaymentAsync.rejected, (state, action) => {
        state.statusCallback = 'error';
      })
      .addCase(callbackPaymentAsync.fulfilled, (state, { payload }) => {
        state.dataCallback = payload.data;
        state.statusCallback = 'finished';
      })
      .addCase(countOrdersAsync.pending, (state) => {
        state.orderCountStatus = 'loading';
      })
      .addCase(countOrdersAsync.fulfilled, (state, { payload }) => {
        state.orderDataCount = payload.data;
        state.orderCountStatus = 'succeeded';
      })
      .addCase(countOrdersWaitingPaymentAsync.pending, (state) => {
        state.countOrdersWaitingPaymentStatus = 'loading';
      })
      .addCase(countOrdersWaitingPaymentAsync.rejected, (state) => {
        state.countOrdersWaitingPaymentStatus = 'failed';
      })
      .addCase(
        countOrdersWaitingPaymentAsync.fulfilled,
        (state, { payload }) => {
          state.countOrdersWaitingPayment = payload.data;
          state.countOrdersWaitingPaymentStatus = 'succeeded';
        },
      )
      .addCase(readOrdersAsync.pending, (state, action) => {
        state.orderDataStatus = 'loading';
      })
      .addCase(readOrdersAsync.rejected, (state, action) => {
        state.orderDataStatus = 'error';
      })
      .addCase(readOrdersAsync.fulfilled, (state, { payload }) => {
        if (payload.data.length) {
          state.orderDataStatus = 'succeeded';
        } else {
          state.orderDataStatus = 'empty';
        }
      })
      .addCase(stateOrdersAsync.pending, (state, action) => {
        state.orderStateStatus = 'loading';
      })
      .addCase(stateOrdersAsync.rejected, (state, action) => {
        state.orderStateStatus = 'error';
      })
      .addCase(stateOrdersAsync.fulfilled, (state, { payload }) => {
        state.stateData = payload.data;
        state.orderStateStatus = 'succeeded';
      })
      .addCase(orderConfirmPaymentAsync.pending, (state, action) => {
        state.orderPaymentConfirmStatus = 'loading';
      })
      .addCase(orderConfirmPaymentAsync.rejected, (state, action) => {
        state.orderPaymentConfirmStatus = 'error';
      })
      .addCase(orderConfirmPaymentAsync.fulfilled, (state, { payload }) => {
        state.orderPaymentConfirmData = payload.data;
        state.orderPaymentConfirmStatus = 'succeeded';
      })
      .addCase(trackOrderAsync.pending, (state, action) => {
        state.trackOrderStatus = 'loading';
      })
      .addCase(trackOrderAsync.rejected, (state, action) => {
        state.trackOrderStatus = 'error';
      })
      .addCase(trackOrderAsync.fulfilled, (state, { payload }) => {
        state.trackOrderData = payload.data;
        state.trackOrderStatus = 'succeeded';
      })
      .addCase(bankAccountAsync.pending, (state, action) => {
        state.bankAccountStatus = 'loading';
      })
      .addCase(bankAccountAsync.rejected, (state, action) => {
        state.bankAccountStatus = 'error';
      })
      .addCase(bankAccountAsync.fulfilled, (state, { payload }) => {
        state.bankAccountStatus = 'succeeded';
      })
      .addCase(packageReceivedAsync.pending, (state, action) => {
        state.packageReceivedStatus = 'loading';
      })
      .addCase(packageReceivedAsync.rejected, (state, action) => {
        state.packageReceivedStatus = 'error';
      })
      .addCase(packageReceivedAsync.fulfilled, (state, { payload }) => {
        state.packageReceivedData = payload.data;
        state.packageReceivedStatus = 'succeeded';
      })
      .addCase(retryAsync.pending, (state, action) => {
        state.retryStatus = 'loading';
      })
      .addCase(retryAsync.rejected, (state, action) => {
        state.retryStatus = 'error';
      })
      .addCase(retryAsync.fulfilled, (state, { payload }) => {
        state.retryData = payload.data;
        state.retryStatus = 'succeeded';
      })
      .addCase(retryByPhoneNumberAsync.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(retryByPhoneNumberAsync.rejected, (state, action) => {
        state.dataOvoFailed = action.payload;
        state.status = 'error';
      })
      .addCase(retryByPhoneNumberAsync.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.status = 'finished';
      });
  },
});

export const {
  SET_DATA,
  SET_DATA_CALLBACK,
  SET_FILTER,
  SET_FILTER_RAW,
  SET_STATE,
} = paymentSlice.actions;
export default paymentSlice.reducer;
