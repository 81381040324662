import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  definePaymentMethod,
  fetchPaymentMethods,
  fetchPaymentMethodByCode,
  fetchListCreditDebitCard,
  fetchMidtransToken,
  addNewCreditDebitCard,
  deleteCreditDebitCardSaved,
} from '~api/payment';
import { Orders } from '~api/orders';
import StringUtils from '~global/utils/string';

const initialState = {
  activedPayment: null,
  paymentPromotion: null,
  isDisabledPlaceOrder: false,
  paymentScreen: 'list_payments',
  status: {
    paymentMethods: 'idle',
    paymentMethodByCode: 'idle',
    definePaymentMethod: 'idle',
    listCreditDebitCard: 'idle',
    tokenMidtrans: 'idle',
    deleteDebitCreditCard: 'idle',
    repayCC: 'idle',
  },
  list: [],
  informationPaymentMethod: {},
  listBankInstallment: [],
  listCreditDebitCard: [],
  savedtokenFailedCC: null,
  selectedVoucherPayment: null,
  selectedInstallment: {},
};

export const setActivedPaymentRequest = createAsyncThunk(
  'PAYMENT/fetchPaymentMethods',
  async () => {
    const response = await fetchPaymentMethods();
    const constructPaymentData = [
      {
        key: 1,
        category: 'last_payment_method',
        list: [],
      },
      {
        key: 2,
        category: 'recommendation_payment_method',
        list: [],
      },
      {
        key: 3,
        category: 'credit_card',
        list: [],
      },
      {
        key: 4,
        category: 'bank_transfer',
        list: [],
      },
      {
        key: 5,
        category: 'other',
        list: [],
      },
    ];

    response.data.data.map((payment, paymentKey) => {
      if (payment.code !== 'cash_on_delivery') {
        if (payment.category === 'last_payment_method') {
          constructPaymentData[0].title = 'Pembayaran yang terakhir dipakai';
          constructPaymentData[0]._id = payment._id;
          constructPaymentData[0].category = payment.category;
          if (payment.code === 'bank_transfer') {
            const bankList = [];
            payment.bank_transfer_list.map((listBank, keyListBank) => {
              bankList.push(listBank);
              bankList[keyListBank].code = 'bank_transfer';
              bankList[keyListBank].is_new = payment.is_new;
              bankList[keyListBank].instant_confirmation =
                payment.instant_confirmation;
              bankList[keyListBank].name = listBank.bank.name;
              bankList[keyListBank].bank_code = StringUtils.toSnakeCase(
                listBank.bank.name,
              );
              bankList[keyListBank].id_payment_method = payment._id;
              bankList[keyListBank].payment_type = payment.name;
            });
            constructPaymentData[0].list = bankList;
          } else {
            constructPaymentData[0].list.push(payment);
          }
        } else if (payment.category === 'recommendation_payment_method') {
          constructPaymentData[1]._id = payment._id;
          constructPaymentData[1].title = 'Payment recommendation';
          constructPaymentData[1].category = payment.category;
          constructPaymentData[1].list.push(payment);
        } else if (payment.category === 'credit_card') {
          constructPaymentData[2]._id = payment._id;
          constructPaymentData[2].title = 'Credit Card/Debit Online';
          constructPaymentData[2].category = payment.category;
          constructPaymentData[2].list.push(payment);
        } else if (
          payment.category === 'bank_transfer' &&
          payment.bank_transfer_list
        ) {
          const bankList = [];
          constructPaymentData[3].title = 'Bank Transfer';
          constructPaymentData[3].category = payment.category;
          constructPaymentData[3]._id = payment._id;
          payment.bank_transfer_list.map((listBank, keyListBank) => {
            bankList.push(listBank);
            bankList[keyListBank].code = 'bank_transfer';
            bankList[keyListBank].is_new = payment.is_new;
            bankList[keyListBank].instant_confirmation =
              payment.instant_confirmation;
            bankList[keyListBank].name = listBank.bank.name;
            bankList[keyListBank].bank_code = StringUtils.toSnakeCase(
              listBank.bank.name,
            );
            bankList[keyListBank].id_payment_method = payment._id;
            bankList[keyListBank].payment_type = payment.name;
          });
          constructPaymentData[3].list = bankList;
        } else if (payment.category === 'other') {
          const constructPayment = payment;
          constructPaymentData[4]._id = payment._id;
          constructPaymentData[4].title = 'See other payment method';
          constructPaymentData[4].category = payment.category;
          constructPaymentData[4].list.push(constructPayment);
        }
      }
    });

    return constructPaymentData.filter((item) => item.list.length);
  },
);

export const definePaymentMethodRequest = createAsyncThunk(
  'PAYMENT/definePaymentMethod',
  async ({ paymentCode, payload }) => {
    const response = await definePaymentMethod({ paymentCode, payload });
    if (paymentCode === 'kredivo') {
      response.data.data.map((info, key) => {
        response.data.data[key].isSelected = key === 0 ? true : false;
      });
    }

    if (paymentCode === 'bank_transfer') {
      if (!response.data.data.user_bank_account) {
        response.data.data.hasUserBankAccount = false;
        response.data.data.user_bank_account = {
          account_number: '',
          name: '',
        };
      } else {
        response.data.data.hasUserBankAccount = true;
      }
    }
    return response.data;
  },
);

export const fetchPaymentMethodByCodeRequest = createAsyncThunk(
  'PAYMENT/fetchPaymentMethodByCodeRequest',
  async (payload) => {
    try {
      const response = await fetchPaymentMethodByCode(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error('Something went wrong!');
    }
  },
);

export const fetchListCreditDebitCardRequest = createAsyncThunk(
  'PAYMENT/fetchListCreditDebitCardRequest',
  async () => {
    const response = await fetchListCreditDebitCard();
    if (Array.isArray(response.data.data) && response.data.data.length) {
      response.data.data.forEach((item, key) => {
        response.data.data[key].indexArray = key;
        response.data.data[key].hasInstallmentPromo = false;
        response.data.data[key].userHasSelectedInstallment = false;
        response.data.data[key].idInstallment = null;
      });
    }
    return response.data;
  },
);

export const fetchMidtransTokenRequest = createAsyncThunk(
  'PAYMENT/fetchMidtransToken',
  async (payload) => {
    const response = await fetchMidtransToken(payload);
    return response.data;
  },
);

export const addNewCreditDebitCardRequest = createAsyncThunk(
  'PAYMENT/addNewCreditDebitCard',
  async (payload) => {
    const response = await addNewCreditDebitCard(payload);
    return response.data;
  },
);

export const deleteCreditDebitCardSavedRequest = createAsyncThunk(
  'PAYMENT/deleteDebitCreditCardSaved',
  async (payload) => {
    const response = await deleteCreditDebitCardSaved(payload);
    return response.data;
  },
);

export const repayCC = createAsyncThunk('PAYMENT/repayCC', async (payload) => {
  const response = await Orders.repayCC(payload);
  return response.data;
});

const paymentSlice = createSlice({
  name: 'PAYMENT',
  initialState,
  reducers: {
    SET_STATUS_PAYMENT_API(state, { data, name }) {
      state.status[name] = data;
    },
    SET_ACTIVED_PAYMENT(state, action) {
      state.activedPayment = action.data;
    },
    SET_PAYMENT_PROMOTION(state, action) {
      state.paymentPromotion = action.data;
    },
    SET_PAYMENT_SCREEN(state, action) {
      state.paymentScreen = action.data;
    },
    SET_USER_BANK_ACCOUNT(state, action) {
      state.informationPaymentMethod = {
        ...state.informationPaymentMethod,
        user_bank_account: {
          ...state.informationPaymentMethod.user_bank_account,
          ...action.data,
        },
      };
    },
    SET_IS_DISABLED_PLACEORDER(state, action) {
      state.isDisabledPlaceOrder = action.data;
    },
    SET_KREDIVO_INSTALLMENT(state, action) {
      state.informationPaymentMethod.map((info, key) => {
        state.informationPaymentMethod[key].isSelected = false;
      });
      state.informationPaymentMethod[action.key].isSelected = true;
    },
    SET_LIST_PAYMENT_METHOD(state, action) {
      state.list = action.data;
    },
    SET_LIST_DEBIT_CREDIT_CARD(state, action) {
      state.listCreditDebitCard = action.data;
    },
    DELETE_CREDIT_CARD(state, { data }) {
      const copyListCreditDebitCard = [...state.listCreditDebitCard];

      state.listCreditDebitCard.map((card, key) => {
        if (data['_id'] === card['_id']) {
          copyListCreditDebitCard.splice(key, 1);
        }
      });
      state.listCreditDebitCard = [...copyListCreditDebitCard];
    },
    ADD_CREDIT_CARD(state, { data }) {
      state.listCreditDebitCard = [...state.listCreditDebitCard, ...data];
    },
    SET_TOKEN_FAILED_CC(state, { data }) {
      state.savedtokenFailedCC = data;
    },
    setSelectedPaymentVoucher(state, { data }) {
      state.selectedVoucherPayment = data;
    },
    // setInstallmentToTheCard(state, { key, idInstallment }) {
    //   for (let i = 0; i < state.listCreditDebitCard.length; i++) {
    //     state.listCreditDebitCard[key].hasInstallmentPromo = false;
    //     state.listCreditDebitCard[key].idInstallment = null;
    //   }
    //   state.listCreditDebitCard[key].idInstallment = idInstallment;
    //   state.listCreditDebitCard[key].hasInstallmentPromo = true;
    // },
    // selectInstallmentTerms(state, { data }) {
    //   const { keyInstallmentDetails, value, keyDebitCard, location_from } = data;
    //   let copyDeepListCreditDebitCard = JSON.parse(JSON.stringify(state.listCreditDebitCard));
    //   //location_from 'card' and 'form'
    //   if (location_from === 'card') {
    //     if (
    //       copyDeepListCreditDebitCard[keyDebitCard] &&
    //       copyDeepListCreditDebitCard[keyDebitCard].installmentTermDetails[keyInstallmentDetails]
    //     ) {
    //       copyDeepListCreditDebitCard[keyDebitCard].installmentTermDetails[keyInstallmentDetails].is_selected = value;
    //       state.listCreditDebitCard = copyDeepListCreditDebitCard;
    //       console.log(copyDeepListCreditDebitCard)
    //     }
    //   }
    // },
    setSelectedInstallment(state, { data }) {
      state.selectedInstallment = data;
    },
    updateSelectedInstallmentDetail(state, { key }) {
      state.selectedInstallment.installment_term_details.forEach(
        (item, itemKey) => {
          state.selectedInstallment.installment_term_details[
            itemKey
          ].is_selected = false;
        },
      );
      state.selectedInstallment.installment_term_details[
        key
      ].is_selected = true;
    },
    updateUserHasSelectedInstallment(state, { key, value }) {
      state.listCreditDebitCard[key].userHasSelectedInstallment = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActivedPaymentRequest.pending, (state, action) => {
        state.status.paymentMethods = 'loading';
      })
      .addCase(setActivedPaymentRequest.rejected, (state, action) => {
        state.status.paymentMethods = 'error';
      })
      .addCase(setActivedPaymentRequest.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status.paymentMethods = 'finished';
      })
      .addCase(definePaymentMethodRequest.pending, (state, action) => {
        state.status.definePaymentMethod = 'loading';
      })
      .addCase(definePaymentMethodRequest.rejected, (state, action) => {
        state.status.definePaymentMethod = 'error';
      })
      .addCase(definePaymentMethodRequest.fulfilled, (state, action) => {
        state.informationPaymentMethod = Array.isArray(action.payload.data)
          ? action.payload.data.filter((detail) => !detail.is_installment)
          : action.payload.data;
        if (Array.isArray(action.payload.data) && action.payload.data.length) {
          // check if detail payment method information contains installment promo
          state.listBankInstallment = action.payload.data.filter(
            (detail) => detail.is_installment,
          );
        }

        state.status.definePaymentMethod = 'finished';
      })
      .addCase(fetchListCreditDebitCardRequest.pending, (state, action) => {
        state.status.listCreditDebitCard = 'loading';
      })
      .addCase(fetchListCreditDebitCardRequest.rejected, (state, action) => {
        state.status.listCreditDebitCard = 'error';
      })
      .addCase(fetchListCreditDebitCardRequest.fulfilled, (state, action) => {
        state.listCreditDebitCard = action.payload.data;
        state.status.listCreditDebitCard = 'finished';
      })
      .addCase(fetchMidtransTokenRequest.pending, (state, action) => {
        state.status.midtransToken = 'loading';
      })
      .addCase(fetchMidtransTokenRequest.rejected, (state, action) => {
        state.status.midtransToken = 'error';
      })
      .addCase(fetchMidtransTokenRequest.fulfilled, (state, action) => {
        state.status.midtransToken = 'finished';
      })
      .addCase(addNewCreditDebitCardRequest.pending, (state, action) => {
        state.status.addNewCard = 'loading';
      })
      .addCase(addNewCreditDebitCardRequest.rejected, (state, action) => {
        state.status.addNewCard = 'failed';
      })
      .addCase(addNewCreditDebitCardRequest.fulfilled, (state, action) => {
        state.status.addNewCard = 'succeeded';
      })
      .addCase(deleteCreditDebitCardSavedRequest.pending, (state, action) => {
        state.status.deleteDebitCreditCard = 'loading';
      })
      .addCase(deleteCreditDebitCardSavedRequest.rejected, (state, action) => {
        state.status.deleteDebitCreditCard = 'error';
      })
      .addCase(deleteCreditDebitCardSavedRequest.fulfilled, (state, action) => {
        state.status.deleteDebitCreditCard = 'finished';
      })
      .addCase(fetchPaymentMethodByCodeRequest.pending, (state, action) => {
        state.status.paymentMethodByCode = 'loading';
      })
      .addCase(fetchPaymentMethodByCodeRequest.rejected, (state, action) => {
        state.status.paymentMethodByCode = 'error';
      })
      .addCase(fetchPaymentMethodByCodeRequest.fulfilled, (state, action) => {
        state.activedPayment = action.payload.data[0];
        state.status.paymentMethodByCode = 'finished';
      })
      .addCase(repayCC.pending, (state, action) => {
        state.status.repayCC = 'loading';
      })
      .addCase(repayCC.rejected, (state, action) => {
        state.status.repayCC = 'error';
      })
      .addCase(repayCC.fulfilled, (state, action) => {
        state.status.repayCC = 'finished';
      });
  },
});

export const {
  SET_STATUS_PAYMENT_API,
  SET_ACTIVED_PAYMENT,
  SET_PAYMENT_PROMOTION,
  SET_PAYMENT_SCREEN,
  SET_INFORMATION_PAYMENT_METHOD,
  SET_LIST_DEBIT_CREDIT_CARD,
  SET_LIST_PAYMENT_METHOD,
} = paymentSlice.actions;
export default paymentSlice.reducer;
